import { LocationWithZoneLite } from '@m/types/api';
import { apiFetch } from '@m/utils/http';

export const SiteGroupService = {
  listSitesInSameGroup: ({ siteId }: { siteId: number }) =>
    apiFetch(`/admin/site/${siteId}/site-group/list`),

  getLocationWithZones: ({ siteId }: { siteId: number }) =>
    apiFetch<LocationWithZoneLite>(`/v2/sites/${siteId}/location`),
};
